import React from "react";
import './App.css';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import data from './data/cosmes.json';
import Menu from './components/Menu';
import Frame from './components/Frame';
import Top from './components/Top';
import { BrowserRouter as BrowserRouter, Routes, Route } from 'react-router-dom';
import CosmeDetail from "./components/CosmeDetail";
import Privacy from "./components/Privacy";
import Preposition from "./components/test/Preposition";
import PrepositionEn from "./components/test/PrepositionEn";
import GroupPreposition from "./components/test/GroupPreposition";
import GroupPrepositionEn from "./components/test/GroupPrepositionEn";
import ContactForm from "./components/ContactForm";
import Error404 from './components/Error404';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/test/preposition/ja" element={<Preposition />} />
          <Route path="/test/preposition/en" element={<PrepositionEn />} />
          <Route path="/test/group-preposition/ja" element={<GroupPreposition />} />
          <Route path="/test/group-preposition/en" element={<GroupPrepositionEn />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
