import React from "react";
import { Helmet } from "react-helmet";
import Frame from './Frame';

export default function Top() {
    return (
        <>
        <Helmet>
            <title>英語の勉強に役立つ無料の単語テストサイト - Iketama IRT English Tests</title>
            <meta name="description"
                  content="Iketama IRT English Testsはアカウント登録不要で、英語の勉強に役立つ無料の単語テストを提供します。
                単語は毎日更新され続け、日々の英語の学びを手助けします。"
            />
        </Helmet>
        <div className="flex-1 bg-base-200">
            <div className=" py-5 lg:px-30 px-10 text-center">
                <p className="py-2 text-gray-500"><span className="text-primary-focus text-bold">Iketama IRT English Test</span>は英単語を覚えるのを助けるためのWebサービスです。</p>
                <p className="py-2 text-gray-500">アカウント登録なしで、無料で単語テストを行うことができます。</p>
                <p className="py-2 text-gray-500">ぜひこのサイトを活用してあなたの英語力を上げてみましょう！</p>
            </div>
            <div className="flex-col">
                <div className="py-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-0 xl:px-50">
                    <div className="py-3">
                        <Frame title="前置詞 単語 和訳 (全39問)" url="test/preposition/ja" discribe="位置などの関係性を表す前置詞の単語テストです。" img="https://placeimg.com/400/100/animals" />
                    </div>
                    <div className="py-3">
                        <Frame title="前置詞 単語 英訳 (全39問)" url="test/preposition/en" discribe="位置などの関係性を表す前置詞の単語テストです。" img="https://placeimg.com/400/100/tech" />
                    </div>
                    <div className="py-3">
                        <Frame title="群前置詞 和訳 (全25問)" url="test/group-preposition/ja" discribe="二語以上の単語がつながり前置詞のように表現される群前置詞のテストです。" img="https://placeimg.com/400/100/animals" />
                    </div>
                    <div className="py-3">
                        <Frame title="群前置詞 英訳 (全25問)" url="test/group-preposition/en" discribe="二語以上の単語がつながり前置詞のように表現される群前置詞のテストです。" img="https://placeimg.com/400/100/tech" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}