import React from "react";
import { Link } from "react-router-dom";

export  function Header() {
  return (
    <div class="navbar bg-primary">
      <div class="navbar-start">
        <div class="dropdown">
          <label tabindex="0" class="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
          </label>
          <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-80">
            <li className="mx-5"><Link to="/">トップページ</Link></li>
            <li className="mx-5"><Link to="/privacy">プライバシーポリシー</Link></li>
            <li className="mx-5"><Link to="/contact-form">お問い合わせ</Link></li>
            <li className="text-primary-focus text-sm mx-8 my-5">無料のミニテスト</li>
            <li className="mx-5"><Link to="/test/preposition/ja">前置詞 単語 和訳 (全39問)</Link></li>
            <li className="mx-5"><Link to="/test/preposition/en">前置詞 単語 英訳 (全39問)</Link></li>
            <li className="mx-5"><Link to="/test/group-preposition/ja">群前置詞 単語 和訳 (全25問)</Link></li>
            <li className="mx-5"><Link to="/test/group-preposition/en">群前置詞 単語 英訳 (全25問)</Link></li>
          </ul>
        </div>
      </div>
      <div class="navbar-center break-words">
       <Link to="/"> <h1 class="normal-case text-xl break-words text-primary-content text-bold">Iketama IRT English Tests</h1></Link>
      </div>
      <div class="navbar-end">
      </div>
    </div>
  );
}