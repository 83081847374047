import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet";



export default function ContactForm () {
  const form = useRef();
  const [from_name, setFromName] =useState('');
  const [nameError, setNameError] = useState('')
  const [from_email, setFromEmail] =useState('');
  const [emailError, setEmailError] = useState('')
  const [message, setMessage] =useState('');
  const [messageError, setMessageError] = useState('')
  const [validationError, setValidationError] = useState(false);
  const [isSentSuccess, setIsSentSuccess] = useState(false);

  const handleNameBlur = (e) => {
    const from_name = e.target.value
    if (!from_name) {
      setIsSentSuccess(false);
      setNameError('入力必須です')
    } else {
      setIsSentSuccess(false);
      setNameError()
    }
  }

  const handleEmailBlur = (e) => {
    const from_email = e.target.value
    if (!from_email) {
      setIsSentSuccess(false);
      setEmailError('入力必須です')
    } else {
      setIsSentSuccess(false);
      setEmailError()
    }
  }

  const handleMessageBlur = (e) => {
    const message = e.target.value
    if (!message) {
      setIsSentSuccess(false);
      setMessageError('入力必須です')
    } else {
      setIsSentSuccess(false);
      setMessageError()
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();

    if(!from_email || !from_name || !message) {
        setIsSentSuccess(false);
        setValidationError(true);
        return;
    }

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
          console.log("info: "+result.text);
          setIsSentSuccess(true);
          setValidationError(false);
      }, (error) => {
          console.log("error: "+error.text);
      });
  };

  return (
      <>
        <Helmet>
            <title>お問い合わせ - Iketama IRT English Tests</title>
        </Helmet>
        <div className="flex-1">
            <div className="flex-col">
                <div className="py-2 container mx-auto">
                    <div className="flex flex-col">
                        <div className="container mx-auto py-5 lg:px-30 px-10 text-gray-500">
                            <div class="bg-white py-6 sm:py-8 lg:py-12">
                                <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                                    <h2 class="text-2xl text-primary-focus lg:text-3xl font-bold text-center mb-4 md:mb-8">お問い合わせ</h2>

                                    <form ref={form} onSubmit={sendEmail} class="max-w-lg border rounded-lg mx-auto">
                                        <div class="flex flex-col gap-4 p-4 md:p-8">
                                            <div>
                                                <label for="from_name" class="inline-block text-gray-800 text-sm sm:text-base mb-2">
                                                    お名前<span className='text-red-900'>*</span>
                                                </label>
                                                <input onBlur={handleNameBlur} onChange={(e)=>{setFromName(e.target.value)}} type="text" name="from_name" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                                {
                                                    nameError && <p className='text-red-900'>{nameError}</p>
                                                }
                                            </div>
                                            <div>
                                                <label for="from_email" class="inline-block text-gray-800 text-sm sm:text-base mb-2">
                                                    Email<span className='text-red-900'>*</span>
                                                </label>
                                                <input onBlur={handleEmailBlur} onChange={(e)=>{setFromEmail(e.target.value)}}  type="email" name="from_email" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                                {
                                                    emailError && <p className='text-red-900'>{emailError}</p>
                                                }
                                            </div>
                                            <div>
                                                <label for="message" class="inline-block text-gray-800 text-sm sm:text-base mb-2">
                                                    メッセージ<span className='text-red-900'>*</span>
                                                </label>
                                                <textarea onBlur={handleMessageBlur} onChange={(e)=>{setMessage(e.target.value)}}  name="message" class="h-60 w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                                {
                                                    messageError && <p className='text-red-900'>{messageError}</p>
                                                }
                                            </div>
                                            <input type="submit" value="送信する" />
                                            {
                                                validationError && <p className='text-red-900'>入力必須の項目を埋めてください。</p>
                                            }
                                            {
                                                isSentSuccess && <p className='text-green-500'>問い合わせを送信いたしました。</p>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
  );
};