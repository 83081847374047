import React from "react";
import { Link } from "react-router-dom";

export default function Frame({ title, url, discribe, img }) {
    return (
        <div className="card w-80 h-60 bg-base-100 shadow-xl container mx-auto">
            <Link to={'/' + url}>
            <figure><img src={img} alt="people" /></figure>
                <div className="card-body">
                    <h2 className="card-title text-secondary-focus">{title}</h2>
                    <p className="text-gray-500">{discribe}</p>
                </div>
            </Link>
        </div>
    );
}
