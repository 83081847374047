import React, { userState, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Error404() {
    return (
        <div className="flex-1">
            <div className="flex-col">
                <div className="py-2 container mx-auto">
                <div class="bg-white py-6 sm:py-8 lg:py-12">
                    <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                        <div class="w-full sm:w-96 h-96 flex justify-center items-center bg-gray-100 shadow-lg rounded-lg overflow-hidden relative mx-auto">
                        <img src="https://images.unsplash.com/photo-1530651788726-1dbf58eeef1f?auto=format&q=75&fit=crop&w=600" loading="lazy" alt="Photo by Connor Botts" class="w-full h-full object-cover object-center absolute inset-0" />

                        <div class="flex flex-col justify-center items-center relative p-8 md:p-16">
                            <h1 class="text-white text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-2">ページが見つかりません</h1>

                            <p class="text-gray-200 md:text-lg text-center mb-8">そのページは削除または移動した可能性があります。</p>

                            <Link to={'/'}>
                                <button className="btn btn-secondary mx-4 w-42">トップページ</button>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}