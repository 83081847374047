import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
    return (
        <footer className="footer items-center p-4 bg-neutral text-neutral-content">
            <div className="items-center grid-flow-col">
                <p>Copyright © 2022 - All right reserved</p>
            </div> 
            <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                <a href="/" >トップページ</a>
                <a href="/privacy" >プライバシーポリシー</a>
                <a href="/contact-form" >お問い合わせ</a>
            </div>
        </footer>
    );
}
