import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function selectRandomNumberFromArray({ length, choicedArray }) {
    let tmpChoicedArray = [];
    if (length === choicedArray.length) {
        choicedArray = tmpChoicedArray;
    }
    let num = Math.floor(Math.random() * length);
    while (choicedArray.indexOf(num) != -1) {
        num = Math.floor(Math.random() * length);
    }
    return num;
}

function selectOtherOptions({ length, num }) {
    let b = Math.floor(Math.random() * length);
    let c = Math.floor(Math.random() * length);
    let d = Math.floor(Math.random() * length);
    while(!( num != b && num != c && num != d &&
        b != c && b != d && c != d)) {
        b = Math.floor(Math.random() * length);       
        c = Math.floor(Math.random() * length);
        d = Math.floor(Math.random() * length);
    }
    let index_a = Math.floor(Math.random() * 4);
    let index_b = Math.floor(Math.random() * 4);
    let index_c = Math.floor(Math.random() * 4);
    let index_d = Math.floor(Math.random() * 4);
    while (!( index_a != index_b && index_a != index_c && index_a != index_d &&
            index_b != index_c && index_b != index_d && index_c != index_d)) {
        index_a = Math.floor(Math.random() * 4);
        index_b = Math.floor(Math.random() * 4);
        index_c = Math.floor(Math.random() * 4);
        index_d = Math.floor(Math.random() * 4);
    }
    const choices = [];
    choices[index_a] = num;
    choices[index_b] = b;
    choices[index_c] = c;
    choices[index_d] = d;
    return choices;
}

function setToChociedArray({length, choicedArray, num}) {
    let tmpChoicedArray = choicedArray;
    let emptyArray = [];
    if (tmpChoicedArray.length == length) {
        tmpChoicedArray = emptyArray;
    }
    if (tmpChoicedArray.indexOf(num) == -1) {
        tmpChoicedArray.push(num);
    }
    return tmpChoicedArray;
}

function searchIndexOfNum({choices, num}) {
    return choices.indexOf(num);
}

function inclementAroundNumber({aroundNumber, choicedArray, length}) {
    if(choicedArray.length === length) {
        aroundNumber++;
    }
    return aroundNumber;
}

function calclateCorrectRate({sumOfCorrects, sumOfAnswer}) {
    console.log("sumOfCorrects:"+sumOfCorrects);
    console.log("sumOfAnswer:"+sumOfAnswer);
    if(sumOfAnswer === 0) {
        return 0;
    }
    return sumOfCorrects / sumOfAnswer;
}

export default function Preposition({ data }) {
    const [answer, setAnswer] = useState("");
    const [array, setArray] = useState(data.filter((v) => v.part_of_speech == "Preposition"));
    const [length, setLength] = useState(array.length);
    const [choicedArray, setChoisedArray] = useState([]);
    const [isCorrect, setIsCorrect] = useState(0);
    const [isAnswered, setIsAnswered] = useState(false);
    const [aroundNumber, setAroundNumber] = useState(1);
    const [sumOfCorrects, setSumOfCorrects] = useState(0);

    const [num, setNum] = useState(() => {
        const initialNum = selectRandomNumberFromArray({length, choicedArray});
        return initialNum;
    });

    const [choices, setChoices] = useState(() => {
        const initialChoices = selectOtherOptions({length, num});
        return initialChoices;
    });

    const [indexNum, setIndexNum] = useState(() => {
        const initialIndexNum = searchIndexOfNum({choices, num});
        return initialIndexNum;
    });

    const onButtonClick = () => {
        const tmpAroundNumber = inclementAroundNumber({aroundNumber, choicedArray, length});
        const tmpSumOfCorrects = isCorrect==1 ? sumOfCorrects+1 : sumOfCorrects;
        const num = selectRandomNumberFromArray({length, choicedArray}); 
        const tmpChoicedArray = setToChociedArray({length, choicedArray, num});
        const choices = selectOtherOptions({length, num});
        const indexNum = searchIndexOfNum({choices, num});
        setSumOfCorrects(tmpSumOfCorrects);
        setAroundNumber(tmpAroundNumber);
        setChoisedArray(tmpChoicedArray);
        setNum(num);
        setChoices(choices);
        setIndexNum(indexNum);
        setAnswer("");
        setIsCorrect(0);
        setIsAnswered(false);
    }

    return (
        <>
        <Helmet>
            <title>前置詞 英単語 和訳テスト - Iketama IRT English Tests</title>
            <meta name="description"
                  content="Iketama IRT English Testsはアカウント登録不要で、英語の勉強に役立つ無料の単語テストを提供します。
                           このページでは位置などの関係を表す前置詞の英単語 (全39問) を和訳するテストを無料で行うことができます。
                           ぜひ実施してみてください。"
            />
        </Helmet>
        <div className="flex-1">
            <div className="flex-col">
                <div className="py-2 container mx-auto">
                <div className="flex flex-col">
                    <div className="overflow-x-auto ">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden mx-2 rounded-2xl">
                            <h2 className="text-center my-3 text-2xl font-medium text-gray-500">前置詞 和訳テスト</h2>
                            <div class="stats  mx-auto my-3 flex justify-between">
                                <div class="stat place-items-center">
                                    <div class="stat-value text-base">{aroundNumber}週目</div>
                                </div>
                                <div class="stat place-items-center">
                                    <div class="stat-value text-primary text-base">{choicedArray.length}/{length}問目</div>
                                </div>
                                <div class="stat place-items-center">
                                    <div class="stat-value text-base">正解率{choicedArray.length*aroundNumber === 0 ? 0 : Math.floor((sumOfCorrects/(choicedArray.length+((aroundNumber-1)*length)))*100)}%</div>
                                </div>
                            </div>
                            <table className="min-w-full border text-center rounded-2xl">
                            <thead className="border-b">
                                <tr>
                                <th colSpan="2" scope="col" className="text-lg font-medium text-gray-900 px-6 py-4 border-r bg-primary">
                                    ４つの中から正しい日本語を選んでください
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-b">
                                <td colSpan="2" className="text-sm text-gray-900 font-light px-6 py-4 border-r bg-primary">
                                    <span className="text-3xl font-bold">{array[choices[indexNum]].english_name}</span>
                                    <p className="break-words text-base font-medium text-gray-500 my-1">{array[choices[indexNum]].example_english}</p>
                                    { isAnswered &&
                                        <p className="break-words text-base font-medium text-gray-500 my-1">{array[choices[indexNum]].example_japanese}</p>
                                    }
                                </td>
                                </tr>
                                <tr className="border-b">
                                <td onClick={()=>{ if (isAnswered == false) {setIsAnswered(true); setAnswer(array[choices[indexNum]].japanese_name); setIsCorrect((choices[0]==choices[indexNum] ? 1 : 2));}}}
                                    className="h-1/2 w-1/2 break-words text-xl text-gray-900 font-light px-6 py-8 border-r transition duration-300 ease-in-out hover:bg-gray-100">
                                    {array[choices[0]].japanese_name}
                                </td>
                                <td onClick={()=>{ if (isAnswered == false) {setIsAnswered(true); setAnswer(array[choices[indexNum]].japanese_name); setIsCorrect((choices[1]==choices[indexNum] ? 1 : 2));}}}
                                    className="h-1/2 w-1/2 break-words text-xl text-gray-900 font-light px-6 py-8 border-r transition duration-300 ease-in-out hover:bg-gray-100">
                                    {array[choices[1]].japanese_name}
                                </td>
                                </tr>
                                <tr className="border-b">
                                <td onClick={()=>{ if (isAnswered == false) {setIsAnswered(true); setAnswer(array[choices[indexNum]].japanese_name); setIsCorrect((choices[2]==choices[indexNum] ? 1 : 2));}}}
                                    className="h-1/2 w-1/2 break-words text-xl text-gray-900 font-light px-6 py-8 border-r transition duration-300 ease-in-out hover:bg-gray-100">
                                    {array[choices[2]].japanese_name}
                                </td>
                                <td onClick={()=>{ if (isAnswered == false) {setIsAnswered(true); setAnswer(array[choices[indexNum]].japanese_name); setIsCorrect((choices[3]==choices[indexNum] ? 1 : 2));}}}
                                    className="h-1/2 w-1/2 break-words text-xl text-gray-900 font-light px-6 py-8 border-r transition duration-300 ease-in-out hover:bg-gray-100">
                                    {array[choices[3]].japanese_name}
                                </td>
                                </tr>
                                {isAnswered &&
                                    <tr className="bg-white border-b">
                                    {isCorrect == 1 &&
                                        <td colSpan="2" className="text-xl text-gray-900 font-light px-6 py-8 text-center border-r bg-green-200">
                                            <p className="break-words text-green-500 text-lg">正解です</p>
                                            <p className="break-words font-bold my-4">{answer}</p>
                                        </td>
                                    }
                                    {isCorrect == 2 &&
                                        <td colSpan="2" className="text-xl text-gray-900 font-light px-6 py-8 text-center border-r bg-red-200">
                                            <p className="break-words text-red-500 text-lg">不正解です</p>
                                            <p className="break-words font-bold my-4">{answer}</p>
                                        </td>
                                    }
                                    </tr>
                                }
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
                { isAnswered && 
                    <div className="flex justify-center my-5">
                        <button onClick={onButtonClick}
                            className="btn mx-4 w-42">次の問題を表示</button>
                    </div>
                }
                </div>
            </div>
        </div>
        </>
    );
}