import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TestPreposition from "./TestPreposition";

export default function Preposition() {
    const [ data, setData ] = useState();
    const [ error, setError ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        fetch('https://shielded-anchorage-72533.herokuapp.com/terms')
            .then(response => response.json())
            .then(setData)
            .then(() => setLoading(false))
            .catch(console.error);
    }, []);

    if (error)
        return JSON.parse(JSON.stringify(error, null, 2));
    
    if (loading)
        return <h1>loading</h1>

    if(!data)
        return null;

    return (
        <>
        <TestPreposition data={data} />
        </>
    );
}