import React from "react";
import { Helmet } from "react-helmet";

export default function Privacy() {
    return(
        <>
        <Helmet>
            <title>プライバシーポリシー - Iketama IRT English Tests</title>
        </Helmet>
        <div className="flex-1">
            <div className="flex-col">
                <div className="py-2 container mx-auto">
                <div className="flex flex-col">
                    <div className="overflow-x-auto "></div>
                        <div className="container mx-auto py-5 lg:px-30 px-10 text-gray-500">
                            <h1 className="text-2xl py-3 text-primary-focus font-bold">プライバシーポリシー</h1>

                            <h2 className="underline text-xl py-5 text-primary-focus font-bold">当サイトに掲載されている広告について</h2>
                            <p>　当サイトでは、第三者配信の広告サービス（Googleアドセンス、Link-A、A8.net、Amazonアソシエイト等）を利用しています。
                            このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。
                            またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、<a className="text-blue-700" href="https://policies.google.com/technologies/ads?gl=jp" target="_blank">こちら</a>をご覧ください。
                            </p>

                            <h2 className="underline text-xl py-5 text-primary-focus font-bold">引用・転載について</h2>
                            
                            <p>　当サイトはリンクフリーですが、コンテンツ内の画像、文章の無断転載は固くお断りしています。</p>

                            <p>引用は下記を厳守の上、著作権の範囲内で引用されるようお願いします。</p>
                            <ul className="px-10 list-disc">
                                <li>正当な範囲内（最小限の複製）で用いること</li>
                                <li>引用範囲を分かるように明示すること</li>
                                <li>当サイトからの引用である旨を必ず明示すること</li>
                                <li>当サイトからの引用がコンテンツの主とならないこと</li>
                                <li>※引用元として対象記事タイトルでのリンクをお願いします。</li>
                            </ul>

                            
                            <h2 className="underline text-xl py-5 text-primary-focus font-bold">当サイトが使用しているアクセス解析ツールについて（Google Analytics）</h2>
                            <p>
                                　当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
                                このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                                このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
                                この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
                                この規約に関して、詳しくは<a className="text-blue-700" href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank">こちら</a>をご覧ください。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}